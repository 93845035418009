import request from '@/utils/http.js'

// 获取sessionid
export const openSessionid = () => {
  return request({
    url: `/authority-center/open/sessionid`,
    method: 'get',
  })
}


// 获取图片验证码
export const openVerifyCode = (params) => {
  return request({
    url: `/authority-center/open/verify_code`,
    method: 'get',
    params
  })
}


export const openwxLogin = (openid) => {
  return request({
    url: `/authority-center/open/wx/Login?openid=${openid}`,
    method: 'get'
  })
}

export const wechatOpenIdLogin = (params) => {
  return request({
    url: `/bid-check-service/open/wechatOpenIdLogin?token=${params.token}`,
    method: 'get',
  })
}

// 获取验证码
export const userregisterCode = (params) => {
  return request({
    url: `/authority-center/open/register/sms_code`,
    method: 'post',
    params
  })
}


// 获取验证码
export const resetCode = (params) => {
  return request({
    url: `/authority-center/open/login/sms_code`,
    method: 'post',
    params
  })
}


// 注册
export const userRegister = (params) => {
  return request({
    url: `/authority-center/open/register`,
    method: 'post',
    params
  })
}


// 用户登录
export const userLogin = (params) => {
  return request({
    url: `/authority-center/open/login_pc_ent`,
    method: 'post',
    params
  })
}


// 获取用户信息
export const userInfo = () => {
  return request({
    url: `/authority-center/common/user/info`,
    method: 'get',
  })
}

// 新增租户用户账号
export const tenantcreate = (data) => {
  return request({
    url: `/authority-center/user/add/account`,
    method: 'post',
    data
  })
}


// 删除租户用户账号
export const tenantdelete = (id) => {
  return request({
    url: `/authority-center/tenant/user/${id}/delete`,
    method: 'delete',
  })
}

// 修改租户用户账号
export const tenantupdate = (data) => {
  return request({
    url: `/authority-center/tenant/user/update`,
    method: 'put',
    data
  })
}

// 设置主账号
export const setAccount = (params) => {
  return request({
    url: `/authority-center/user/set/account`,
    method: 'post',
    params
  })
}


// 获取协议  
export const helplist = (params) => {
  return request({
    url: `/cms-service/open/list`,
    method: 'get',
    params
  })
}

// 协议详情
export const helpListPage = (params) => {
  return request({
    url: `/cms-service/open/SALARY/helpListPage`,
    method: 'get',
    params
  })
}

// 内容详情
export const helpDetailById = (params) => {
  return request({
    url: `/cms-service/open/helpDetailById`,
    method: 'get',
    params
  })
}


// 登录账号修改密码
export const usercChangepwd = (params) => {
  return request({
    url: `/authority-center/common/changepwd`,
    method: 'post',
    params
  })
}

// 修改密码
export const tenantuserChangepwd = (params) => {
  return request({
    url: `/authority-center/tenant/user/changepwd`,
    method: 'post',
    params
  })
}


// 获取微信二维码
export const getwxImg = (state) => {
  return request({
    url: `/bid-check-service/open/wx/${state}/qrcode`,
    method: 'get',
  })
}
// 获取Token
export const getQrToken = () => {
  return request({
    url: `/bid-check-service/open/getQrToken`,
    method: 'get',
  })
}

export const getLoginQrCode = (token) => {
  return request({
    url: `/bid-check-service/open/getLoginQrCode?token=${token}`,
    method: 'get',
  })
}

export const getManagementList = (params) => {
  return request({
    // url: `/bid-check-service/management/list`,
    url: `/bid-check-service/open/doclist`,
    method: 'get',
    params
  })
}

export const openCheck = (params) => {
  return request({
    url: `/authority-center/open/check`,
    method: 'post',
    params
  })
}

// 重置密码
export const resetPwd = ({ newPassword, mobile }) => {
  return request({
    url: `/authority-center/open/resetpwd`,
    method: 'post',
    params: { newPassword, mobile }
  })
}


// 修改openid
export const updateOpenId = (data) => {
  return request({
    url: `/authority-center/common/update/openId`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 检测用户是否关注公众号
export const checkAdd = (openid) => {
  return request({
    url: `/bid-check-service/open/wx/userInfo/1/${openid}`,
    method: 'get',
  })
}


// 华为云获取用户信息
export const getHwinfo = (params) => {
  return request({
    url: `/bid-check-service/open/redirect`,
    method: 'get',
    params
  })
}

// 检测毕昇
export const checkBs = (userName) => {
  return request({
    url: `/bid-check-service/open/checkAndRegistBsUser/${userName}`,
    method: 'get',
  })
}


// 绑定手机号
export const bindMobile = (data) => {
  return request({
    url: `/authority-center/user/updateMobile`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
