<template>
  <div class="main">
    <contenttitle title="我的订单" :helpUrl="`saas-ban/yong-hu-zhong-xin/wo-de-ding-dan`"></contenttitle>
    <div class="flexContent_wrap">
      <div class="flexContent">
        <div class="searchBox flexl">
          <el-select clearable style="width: 160px;margin-right:10px" v-model="tableRequest.pack" placeholder="套餐类型">
            <el-option v-for="item in  packageoption" :key="item.packageType" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select clearable style="width: 160px;" v-model="tableRequest.payment" placeholder="支付方式">
            <el-option v-for="item in payTypeoption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="tableRequest.code" style="width:220px;margin:0 10px" placeholder="请输入订单编号"></el-input>
          <!-- <el-date-picker v-model="tableRequest.dateCreated"value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="请选择创建时间">
          </el-date-picker> -->
          <el-date-picker
            v-model="searchTime"
            type="daterange"
            start-placeholder="开始日期"
            @change="changetime"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期">
          </el-date-picker>
          <div style="margin-left:20px ;" class="mainBtn" @click="searchList"><i class="el-icon-search"></i> 搜索</div>
          <div style="margin-left:20px ;" class="mainBtnWhite" @click="reset"><i class="el-icon-refresh-left"></i> 重置
          </div>
        </div>
        <div class="tableBox">
          <el-table :header-cell-style="headerCellStyle" :row-style="rowCellStyleFun" :data="tableData"
            style="width: 100%" empty-text="暂无购买记录">
            <el-table-column prop="code" label="订单编号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="createdBy" label="创建人" align="center" width="200">
            </el-table-column>
            <el-table-column prop="dateCreated" label="创建时间" align="center" width="200">
              <template slot-scope="scope">
                {{ $moment(scope.row.dateCreated).format('YYYY-MM-DD HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="套餐类型" align="center">
              <template slot-scope="scope">
                {{ scope.row.pointsId ? '知点' : '' }}
                {{ scope.row.pointsId && scope.row.packageId ? ';' : '' }}
                {{
                  scope.row.packageId ?scope.row.packageName=='桌面高级版'||scope.row.packageName=='桌面标准版'?'': '知卡' : '' }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="套餐内容" align="center" width="100">
              <template slot-scope="scope">
                {{ scope.row.pointsName ? scope.row.pointsName + 'x' + scope.row.pointsNum : '' }}
                {{ scope.row.pointsId && scope.row.packageId ? ';' : '' }}
                {{ scope.row.packageName ?
                  scope.row.packageName + 'x' + scope.row.packageNum : '' }}
              </template>
            </el-table-column>
            <el-table-column prop="amountOfMoney" label="订单金额（元）" align="center">
              <template slot-scope="scope">
                ￥{{ scope.row.amountOfMoney }}
              </template>
            </el-table-column>
            <el-table-column prop="payment" label="支付方式" align="center">
              <template slot-scope="scope">
                <!-- <div v-if="scope.row.status == 2" style="font-size:12px"> -->
                  <div style="font-size:12px">
                  {{ scope.row.payment == 0 ? "支付宝支付" : scope.row.payment == 1 ? "微信支付" : '银行卡支付' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="订单状态">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 0" style="font-size:12px">待支付</div>
                <div v-if="scope.row.status == 1" style="font-size:12px">审核中</div>
                <div v-if="scope.row.status == 2" style="font-size:12px">已支付</div>
                <div v-if="scope.row.status == 3" style="font-size:12px">已关闭</div>
                <div v-if="scope.row.status == 4" style="
                font-size:12px;
                color: #ff6161;
                display: flex;
                align-items: center;
                color: red;
              ">
                  审核不通过
                  <el-tooltip :content="scope.row.memo" placement="bottom" effect="light">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </div>
                <!-- <div v-if="scope.row.status == 1" style="color: #ff986f">审核中</div>
          
          <div v-if="scope.row.status == 3" style="color: #ccc">已关闭</div> -->
              </template>
            </el-table-column>
            <el-table-column prop="name" label="操作" align="center" width="150">
              <template slot-scope="scope">
                <div class="btnbox flexsa" v-if="scope.row.status == 0">
                  <div @click="toPay(scope.row)">支付</div>
                  <div @click="toCancel(scope.row.code)">取消</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagebox">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentpage" :page-sizes="[10, 20, 30, 40]" :page-size="tableRequest.size"
            layout="total, sizes, prev, pager, next, jumper" :total="totlenumber">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { orderbuyList, orderCancel } from "@/api/org.js"
import contenttitle from "@/component/contentTitle"

export default {
  components: { contenttitle },
  data () {
    return {
      searchTime: '',
      currentpage: 0,
      rowCellStyle: {
        height: '56px',
        fontSize: "12px",
        color: ' #000000'
      },
      headerCellStyle: {
        height: '56px',
        fontSize: "12px",
        background: ' #D1E9FF',
        color: ' #000000'
      },
      totlenumber: 0,
      tableRequest: {
        pageNumber: 0,
        size: 10,
        pack: '',
        payment: '',
        code: "",
      },
      tableData: [],
      payTypeoption: [
        {
          label: '微信',
          value: 1
        },
        {
          label: '支付宝',
          value: 0
        },
        {
          label: '银行卡',
          value: 2
        },
      ],
      packageoption: [
        {
          label: '知点',
          value:"知点"
        },
        {
          label: '知卡',
          value: '知卡'
        },
        {
          label: '用户升级',
          value: '用户升级'
        },
      ],
    }
  },
  created () {
    this.getlist()
  },
  methods: {
    changetime (val) {
      if(val){
         this.tableRequest.startDate=val[0]
         this.tableRequest.endDate=val[1]
        }else{
          this.tableRequest.startDate=''
          this.tableRequest.endDate=''
        }
    },
    rowCellStyleFun ({ row, rowIndex }) {
      console.log("row", row, rowIndex);
      // if (row.isReader == 1) {
      //   return { opacity: "0.6" };
      // } else {
      //   return {};
      // }
      if (row.status == 3) {
        return {
          height: "56px",
          color: '#ccc'
        }
      } else {
        return {
          height: "56px",
          color: '#354052'
        }

      }

    },
    handleSizeChange (val) {
      this.tableRequest.size = val
      this.tableRequest.pageNumber = 0
      this.getlist()
    },
    searchList(){
      this.tableRequest.pageNumber =0
      this.getlist()
      this.currentpage = 1;
    },
    reset () {
      this.tableRequest= {
        pageNumber: 0,
        size: 10,
        pack: '',
        payment: '',
        code: "",
      }
      this.searchTime=''    
      this.getlist()
      this.currentpage = 1;
     },
    async toCancel (id) {
      let { data } = await orderCancel(id)
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '订单取消成功!',
        })
      } else {
        this.$message({
          message: data.msg || '订单取消失败',
          type: 'error',
        })
      }
      this.getlist()
    },
    toPay (row) {
      let name = ''
      name += row.pointsName ? row.pointsName + 'x' + row.pointsNum : ''
      name += row.packageName ? row.packageName + 'x' + row.packageNum : ''
      this.$router.push(`/orgindex/chosePay?pathaddress=/orgindex/myorder&orderNum=${row.code}&name=${name}&price=${row.amountOfMoney}`)
    },
    handleCurrentChange (val) {
      let page = val
      this.currentpage = val;
      this.tableRequest.pageNumber = page - 1
      this.getlist()
    },
    async getlist () {
      let { data } = await orderbuyList(this.tableRequest)
      this.tableData = data.content
      this.totlenumber = data.totalElements
    },
  }
}
</script>

<style lang="less" scoped>
.main {
  .flexContent_wrap {
    height: calc(100vh - 112px);
  }

  .flexContent {
    position: relative;

    .pagebox {
      position: absolute;
      right: 20px;
      bottom: 10px;
    }

    .tableBox {
      margin-top: 17px;
      width: calc(100vw - 280px);

      .btnbox {
        div {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #006EFE;
          line-height: 17px;
          cursor: pointer;
        }
      }


      /deep/.el-table__body-wrapper {
        max-height: calc(100vh - 330px);
        overflow-y: auto;

      }

      .set {
        font-size: 12px;
      }

      /deep/.cell {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 16px;
      }
    }
  }


}
</style>