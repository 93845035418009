import request from '@/utils/http.js'

// 获取套餐列表
export const packageList = (params) => {
  return request({
    url: `/bid-check-service/client/package/list`,
    method: 'get',
    params
  })
}

// 创建购买订单
export const orderCreate = (data) => {
  return request({
    url: `/bid-check-service/client/order/buy/create`,
    method: 'post',
    data
  })
}


// 获取购买订单列表
export const orderbuyList = (params) => {
  return request({
    url: `/bid-check-service/client/order/buy/list`,
    method: 'get',
    params
  })
}

// 取消订单
export const orderCancel = (id) => {
  return request({
    url: `/bid-check-service/client/order/${id}/cancel`,
    method: 'post',
  })
}

// 获取发票订单列表
export const orderinvoiceList = (params) => {
  return request({
    url: `/bid-check-service/client/order/invoice/list`,
    method: 'get',
    params
  })
}


// 获取发票地址列表
export const invoiceaddressList = (params) => {
  return request({
    url: `/bid-check-service/client/invoice/address/list`,
    method: 'get',
    params
  })
}


// 新增客户发票地址信息
export const invoiceAddressCreate = (data) => {
  return request({
    url: `/bid-check-service/client/invoice/address/save`,
    method: 'post',
    data
  })
}
// 设置默认地址
export const invoiceaddressDefault = (id) => {
  return request({
    url: `/bid-check-service/client/invoice/address/${id}/default`,
    method: 'post',
  })
}


// 删除发票地址
export const invoiceAddressDelete = (data) => {
  return request({
    url: `/bid-check-service/client/invoice/address/delete`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 获取发票信息
export const clientinvoiceInfo = () => {
  return request({
    url: `/bid-check-service/client/invoice/info`,
    method: 'get',
  })
}

// 保存发票信息
export const clientinvoiceInfoSave = (data) => {
  return request({
    url: `/bid-check-service/client/invoice/save`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },

  })
}
// 上传发票图片

// 获取用户已购买套餐信息
export const packageInfo = () => {
  return request({
    url: `/bid-check-service/client/my/package`,
    method: 'get',
  })
}

// 获取积分信息
export const pointsInfo = () => {
  return request({
    url: `/bid-check-service/client/my/points`,
    method: 'get',
  })
}


// 添加发票信息 
export const invoiceorderAdd = (data) => {
  return request({
    url: `/bid-check-service/client/invoice/order/add`,
    method: 'post',
    data
  })
}


// 升级用户订单
export const orderupdateCreate = (params) => {
  return request({
    url: `/bid-check-service/client/order/upgrade/create`,
    method: 'post',
    params
  })
}

// 银行卡支付订单
export const bankPay = (data, code) => {
  return request({
    url: `/bid-check-service/client/order/${code}/card_pay`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 修改手机号与公司名称 ss
export const updatemobileororgName = (data) => {
  return request({
    url: `/authority-center/common/update/mobile/or/orgName`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 获取租户用户账号列表
export const tenantPage = (params) => {
  return request({
    url: `/authority-center/user/page`,
    method: 'get',
    params,
  })
}


// 积分使用列表
export const poingsPage = (params) => {
  return request({
    url: `/bid-check-service/client/points/page`,
    method: 'get',
    params,
  })
}

// 购买订单日志
export const orderLog = (params) => {
  return request({
    url: `/bid-check-service/client/order/log`,
    method: 'get',
    params,
  })
}

// 下载发票
export const invoiceDownload = (orderId) => {
  return request({
    url: `/bid-check-service/client/invoice/${orderId}/download`,
    method: 'post',
    responseType: "blob"
  })
}

// open下载发票
export const invoiceDownloadopen = (orderId) => {
  return request({
    url: `/bid-check-service/open/invoice/${orderId}/download`,
    method: 'post',
    responseType: "blob"
  })
}


// 验证账号和手机号是否已使用
export const checkusername = (data) => {
  return request({
    url: `/authority-center/open/check/username/or/mobile`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 关联账号
export const userassociated = (data) => {
  return request({
    url: `/authority-center/user/associated/account`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 修改用户信息
export const updatemobilemask = (params) => {
  return request({
    url: `/authority-center/user/update/mobile/or/mask`,
    method: 'put',
    params,
  })
}


// 创建评价
export const satisfactionCreate = (data) => {
  return request({
    url: `/bid-check-service/satisfaction/create`,
    method: 'post',
    data,
  })
}

// 获取站内信详情
export const getMessage = (id) => {
  return request({
    url: `/bid-check-service/message/${id}/get`,
    method: 'get',
  })
}

// 批量已读站内信
export const batchRead = (data) => {
  return request({
    url: `/bid-check-service/message/batch/read`,
    method: 'post',
    data,
  })
}

// 删除站内信
export const deleteMessage = (data) => {
  return request({
    url: `/bid-check-service/message/delete`,
    method: 'post',
    data,
  })
}

// 获取站内信列表
export const getMessageList = (params) => {
  return request({
    url: `/bid-check-service/message/list`,
    method: 'get',
    params
  })
}

// 获取站内信列表详情
export const getMessageId = (id) => {
  return request({
    url: `/bid-check-service/message/${id}/get`,
    method: 'get'
  })
}


//标记全部已读站内信
export const allRead = (data) => {
  return request({
    url: `/bid-check-service/message/batch/read/all`,
    method: 'post',
    data,
  })
}
// 获取所有未读数量
export const getUnRead = (params) => {
  return request({
    url: `/bid-check-service/message/get/un/read`,
    method: 'get',
    params
  })
}

// 填写问卷
export const surveysave = (data) => {
  return request({
    url: `/bid-check-service/client/survey/save`,
    method: 'post',
    data
  })
}

// 查询是否填写了问卷
export const surveycheck = () => {
  return request({
    url: `/bid-check-service/client/survey/check`,
    method: 'get',
  })
}
// 获取默认发票抬头列表信息
export const invoiceList = () => {
  return request({
    url: `/bid-check-service/client/invoice/list`,
    method: 'get',
  })
}
// 设置默认发票抬头
export const invoiceDefault = (id) => {
  return request({
    url: `/bid-check-service/client/invoice/${id}/default`,
    method: 'post',
  })
}


// 删除发票抬头信息
export const invoiceDelete = (id) => {
  return request({
    url: `/bid-check-service/client/invoice/${id}/delete`,
    method: 'post',
  })
}

// 获取单机版二维码
export const createAndWxpay = (data) => {
  return request({
    url: `/bid-check-service/open/createAndWxpay`,
    method: 'post',
    data,
    responseType: "blob"
  })
}