<template>
  <div>
    <headerview></headerview>
    <indexTip></indexTip>
    <div class="content-wrap" v-if="showmenu">
      <div class="content1">
        <productevaluation :productShow="productShow" @cancelEvent="cancelEvent" okbtnName="确认反馈"></productevaluation>
        <div class="content1-left">
          <div class="menu-title" v-if="!menucollapse">查重检测</div>
          <div style="padding-left: 5px">
            <img class="menu_icontop" v-if="menucollapse" src="@/assets/img1/icon/icon6.png" alt="" />
          </div>
          <el-menu :collapse-transition="false" :collapse="menucollapse" text-color="#fff"
            :default-active="currentRoute" class="el-menu-vertical-demo" :router="true" @open="handleOpen"
            @close="handleClose" :unique-opened="true">
            <el-submenu index="/index1" :key="222">
              <template slot="title">
                <img src="@/assets/img1/icon/icon1.png" alt="" class="menu_icon" />
                <span>查重服务</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="'/index/groupcheck'" :key="'/index/groupcheck'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  标书查重
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/index2" :key="111">
              <template slot="title">
                <img src="@/assets/img1/icon/icon2.png" alt="" class="menu_icon" />
                <span>查看报告</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="'/index/checkreport'" :key="'/index/checkreport'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  检测报告
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/index3" :key="666">
              <template slot="title">
                <img src="@/assets/img1/icon/icon3.png" alt="" class="menu_icon" />
                <span>检测设置</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="'/index/systemsettings'" :key="'/index/systemsettings'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  阈值设置
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/index4" :key="555">
              <template slot="title">
                <img src="@/assets/img1/icon/icon4.png" alt="" class="menu_icon" />

                <span>归档记录</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="'/index/checkhistory'" :key="'/index/checkhistory'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  存档记录
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- <el-submenu index="/index5" :key="333">
              <template slot="title">
                <img src="@/assets/img1/icon/icon5.png" alt="" class="menu_icon" />
                <span>我的套餐</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="'/index/packageview'" :key="'/index/packageview'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  我的套餐
                </el-menu-item>
                <el-menu-item :index="'/index/purchaserecord'" :key="'/index/purchaserecord'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  购买记录
                </el-menu-item>
                <el-menu-item :index="'/index/userecord'" :key="'/index/userecord'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  使用记录
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->

            <el-submenu index="/index6" :key="777">
              <template slot="title">
                <img src="@/assets/img1/icon/icon5.png" alt="" class="menu_icon" />
                <span>单机版专区</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="'/index/standVersion'" :key="'/index/standVersion'">
                  <span style="margin: 0 11px 0 10px">·</span>
                  单机版专区
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>

          <div class="menu_bottom flexc">
            <i class="el-icon-s-fold" @click="menucollapse = !menucollapse" v-if="!menucollapse"></i>
            <i class="el-icon-s-unfold" @click="menucollapse = !menucollapse" v-else></i>
            <div class="pointbox" v-if="!menucollapse" @click="giveEvaluate">
              <span class="pointinfo">给产品打个分</span>
              <i class="el-icon-d-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="content1-right">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <div class="dialogbox">
      <el-dialog title="修改密码" :visible.sync="dialogVisiblepassword" width="480px" center>
        <el-form :model="passwordruleForm" :rules="rules" ref="passwordruleForm" label-width="100px"
          class="demo-ruleForm">
          <el-form-item label="原密码：" prop="oldPassword">
            <el-input v-model="passwordruleForm.oldPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPassword">
            <el-input v-model="passwordruleForm.newPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="newreadyPassword">
            <el-input v-model="passwordruleForm.newreadyPassword" type="password"></el-input>
          </el-form-item>
        </el-form>
        <div class="btns">
          <div class="btnok" @click="isok">确定</div>
          <div class="btnno" @click="dialogVisiblepassword = false">取消</div>
        </div>
      </el-dialog>
    </div>
    <!-- 
    <div class="tip">
      <div @click="showTip" id="tipList" class="tipList">
        <i class="el-icon-service"></i>
        <span v-if="showtipbox">在线咨询</span>
      </div>
      <div class="list">
        <div class="payroll_wx_Father">
          <img src="@/assets/img1/wx.png" class="tipsImage" />
          <span class="tipsText"> 客户经理微信 </span>
          <div class="imgWrap">
            <img src="@/assets/vx/zsqvx.jpg" />
            <img src="@/assets/vx/zmvx.jpg" />
          </div>
        </div>
        <div class="payroll_wx_Father">
          <img src="@/assets/img1/workvx.png" class="tipsImage" />
          <span class="tipsText"> 客户经理企业微信 </span>
          <div class="imgWrap">
            <img src="@/assets/vx/zsqWork.jpg" />
            <img src="@/assets/vx/zmWork.jpg" />
          </div>
        </div>
        <div class="payroll_wx_Father">
          <img src="@/assets/img1/icon-gzh.png" class="tipsImage" />
          <span class="tipsText"> 关注微信公众号 </span>
          <div class="imgWrap" style="width: auto; left: -130px">
            <img src="@/assets/img1/qrcode.png" />
          </div>
        </div>
      </div>
    </div> -->
    <el-dialog title="绑定手机号" :visible.sync="mobileVisible" width="450px" center :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false">
      <el-form :model="ruleForm" :hide-required-asterisk="true" :rules="changeRules" ref="ruleForm" label-width="70px"
        class="demo-ruleForm">
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码:" prop="smsCode">
          <div class="getcode">
            <el-input v-model="ruleForm.smsCode" placeholder="请输入验证码"></el-input>
            <!-- <img :src="codeimg" alt="" @click="getCode"> -->
            <div class="codebtn" v-if="countdown === 0" @click="startCountdown" v-loading="codeLoading">
              获取验证码
            </div>
            <div class="codebtn" v-else style="font-size: 14px; font-weight: 400; background: #ccc">
              {{ countdown }} 秒后重新获取
            </div>
          </div>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input type="password" v-model="ruleForm.password" placeholder="请输入密码"
            autocomplete="new-password"></el-input>
        </el-form-item>
        <div class="flexc">
          <div class="mainBtn" @click="bindMobile">确定</div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { pointsInfo, satisfactionCreate } from "@/api/org.js";
import {
  userInfo,
  usercChangepwd,
  bindMobile,
  userregisterCode,
} from "@/api/saasuser.js";
import headerview from "@/component/header.vue";
import productevaluation from "@/component/productEvaluation";
import { settoken } from "@/utils";

import indexTip from "@/component/indexTip";
export default {
  mounted() {
    this.getuserInfo();
    this.getuserType();
  },
  components: { headerview, productevaluation, indexTip },
  async created() {
    if (this.$route.path == "/index/groupcheckupload") {
      this.defaultAction = "/index/groupcheck";
    } else if (this.$route.path == "/index/reportlist") {
      this.defaultAction = "/index/checkreport";
    } else if (this.$route.path == "/index/groupcheckfile") {
      this.defaultAction = "/index/checkhistory";
    } else {
      this.defaultAction = this.$route.path;
    }
  },
  data() {
    return {
      productShow: false,
      menucollapse: false,
      countdown: 0,
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^[\w_!@#$%&?._-]{6,16}$/,
            message: "请输入正确的密码",
            trigger: "blur",
          },
        ],
        newreadyPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            pattern: /^[\w_!@#$%&?._-]{6,16}$/,
            message: "请输入正确的密码",
            trigger: "blur",
          },
        ],
      },
      passwordruleForm: {},
      dialogVisiblepassword: false,
      showmenu: true,
      showoff: false,
      index: "",
      defaultAction: "",
      info: {},
      mobileVisible: false,
      ruleForm: {
        mobile: "",
        smsCode: "",
        password: "",
        id: "",
      },
      codeLoading: false,
      changeRules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  computed: {
    currentRoute() {
      if (this.$route.path == "/index/groupcheckupload") {
        return "/index/groupcheck";
      } else if (this.$route.path == "/index/reportlist") {
        return "/index/checkreport";
      } else if (this.$route.path == "/index/groupcheckfile") {
        return "/index/checkhistory";
      } else {
        return this.$route.path;
      }
    },
  },
  methods: {
    bindMobile() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.id = this.info.id;
          let { data } = await bindMobile(this.ruleForm);
          if (data.code == 200) {
            this.mobileVisible = false;
            this.$notify({
              title: "成功",
              message: "手机号绑定成功！",
              type: "success",
            });
          } else {
            this.$notify({
              title: "提示",
              message: data.msg || "手机号绑定失败！",
              type: "error",
            });
          }
          this.getuserInfo();
        } else {
          console.log("file");
        }
      });
    },
    async startCountdown() {
      if (this.codeLoading == true) {
        return;
      }
      let phoneReg = new RegExp(/^1\d{10}$/);
      if (phoneReg.test(this.ruleForm.mobile)) {
        this.codeLoading = true;
        let { data } = await userregisterCode({ mobile: this.ruleForm.mobile });
        if (data.code == 200) {
          this.$notify({
            title: "成功",
            message: "验证码已发送至手机，请注意查收",
            type: "success",
          });
          // 倒计时
          this.countdown = 60; // 设置初始倒计时时间为60秒
          this.codeLoading = false;

          const timer = setInterval(() => {
            this.countdown--; // 每秒减少倒计时时间
            if (this.countdown === 0) {
              clearInterval(timer); // 倒计时结束，清除定时器
            }
          }, 1000);
        } else {
          this.$notify({
            title: "警告",
            message: data.msg || "网络异常",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "请填写正确的手机号码",
          type: "warning",
        });
      }
    },
    cancelEvent(val) {
      this.setSatisfactionCreate(val);
      this.productShow = false;
    },
    async setSatisfactionCreate(val) {
      let { data } = await satisfactionCreate(val);
      if (data.code == 200) {
        this.$notify({
          message: "提交反馈成功！",
          type: "success",
        });
      }
      console.log(data, "data");
    },
    giveEvaluate() {
      this.productShow = true;
    },
    async getuserType() {
      let { data } = await pointsInfo();
      this.pointsInfo = data;
      this.$store.commit("getUsertype", data.userType);
    },
    showTip() {
      this.showtipbox = !this.showtipbox;
    },

    async isok() {
      this.$refs["passwordruleForm"].validate(async (valid) => {
        if (valid) {
          if (
            this.passwordruleForm.newreadyPassword ==
            this.passwordruleForm.newPassword
          ) {
            let { data } = await usercChangepwd(this.passwordruleForm);
            if (data.code == 200) {
              this.$notify({
                message: "密码修改成功,请重新登录",
                type: "success",
              });
              settoken("");
              this.$router.push("/login");
            } else {
              this.$message({
                message: data.msg || "修改密码失败",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: "两次密码不一致，请重新输入",
              type: "error",
            });
          }
        }
      });
    },
    changepassword() {
      this.passwordruleForm = {};
      this.dialogVisiblepassword = true;
    },
    bindingMobile() {
      this.ruleForm = {
        mobile: "",
        smsCode: "",
      };
      this.mobileVisible = true;
    },
    async getuserInfo() {
      let { data } = await userInfo();
      this.info = data;
      this.$store.commit("getUserInfo", data);
      if (this.info.mobile == "") {
        this.bindingMobile();
      }
    },

    linkto(path) {
      if (path != this.$route.path) {
        this.$router.push(path);
      }
      this.showoff = false;
    },

    Home() {
      this.$router.push("/index/Home");
    },

    handleOpen(key, keyPath) { },
    handleClose(key, keyPath) { },
    linktologin(path) {
      if (path == "/login") {
        settoken("");
      }
      this.$router.push(path);
    },
    changepage(path) {
      this.$router.push("/checkindex" + path);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 56px;
  background: #26303e;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 0 75px 0 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Home {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    cursor: pointer;
  }

  .el-icon-bell {
    font-size: 18px;
    // margin: 0 18px;
    cursor: pointer;
  }
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-left img {
  height: 34px;
}

.rightpositon {
  position: relative;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  user-select: none;
}

.userheader {
  width: 40px;
  height: 40px;
  margin: 0 9px;
  border-radius: 100%;
}

.username {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}

.next {
  width: 10px;
  margin-left: 5px;
}

.content-wrap {
  overflow: hidden;
  background: #f6f9ff;
}

.content1 {
  display: flex;
  justify-content: left;
  position: relative;
}

.content1-left {
  padding-top: 10px;
  // max-width: 200px;
  height: calc(100vh - 56px);
  background: #ffffff;
  position: relative;
  background: #1f212e;

  .menu_bottom {
    width: 100%;
    height: 48px;
    background: #26303e;
    position: absolute;
    bottom: 0;
    left: 0;

    .el-icon-s-fold {
      cursor: pointer;
      font-size: 18px;
      color: #fff;
    }

    .el-icon-s-unfold {
      cursor: pointer;
      font-size: 18px;
      color: #fff;
    }

    .pointbox {
      margin-left: 28px;
      cursor: pointer;

      .pointinfo {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }

      .el-icon-d-arrow-right {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-left: 10px;
      }
    }
  }

  /deep/.el-menu {
    background: #1f212e;
    color: #fff !important;
    border: none !important;
  }

  .companybox {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 1200;
    font-size: 16px;
    line-height: 25px;
  }
}

.content1-right {
  width: 100%;
  height: calc(100vh - 56px);
  background: #f3f4f8;
  flex: 1;
  overflow: auto;
}

/deep/.el-submenu__title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.menu-title {
  min-width: 200px;
  height: 50px;
  font-size: 16px;
  font-family: "微软雅黑", "Microsoft Yahei", "Hiragino Sans GB", Tahoma, Arial,
    Helvetica, sans-serif;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
  padding-left: 20px;
}

.navimg {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.backtologin {
  width: 150px;
  background: #f5f7fa;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: absolute;
  top: 48px;
  left: -30px;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .outBox {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 15px 0;

    img {
      width: 18px;
    }

    .img2 {
      display: none;
    }

    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 19px;
      margin-left: 6px;
    }

    .el-icon-user {
      font-size: 20px;
    }
  }

  .outBox:hover>span {
    color: #1990ff !important;
  }

  .outBox:hover>i {
    color: #1990ff !important;
  }

  .outBox:hover>.img1 {
    display: none;
  }

  .outBox:hover>.img2 {
    display: inline-block;
  }

  .backtop {
    margin: 0 auto;
    width: 191px;
    height: 85px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 64px;
      height: 64px;
    }
  }
}

/deep/.el-menu-item {
  padding-left: 20px !important;
}

.flexr {
  display: flex;
  align-items: center;
  justify-content: right;
}

.dialogbox {
  /deep/.el-dialog {
    border-radius: 12px;
    overflow: hidden;
  }

  /deep/.el-dialog__header {
    height: 58px;
    background: #1990ff;
    border-radius: 12px 12px 0px 0px;
  }

  /deep/.el-dialog__title {
    font-size: 18px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500 !important;
    color: #ffffff !important;
    line-height: 58px !important;
  }

  /deep/.el-dialog__close {
    font-size: 18px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500 !important;
    color: #ffffff !important;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;

    div {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }

    .btnno {
      background: linear-gradient(360deg, #eaeef3 0%, #ffffff 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border: 1px solid #ced0da;
      color: #354052;
      margin-left: 12px;
    }

    .btnok {
      background: linear-gradient(360deg, #157bd1 0%, #2da1f8 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border: 1px solid #1585d8;
      color: #ffffff;
    }
  }
}

.gotobuy {
  color: #1825d6;
  cursor: pointer;
}

/deep/.el-menu-item:hover {
  background-color: #006efe !important;
  color: #fff;
}

/deep/.el-submenu__title:hover {
  background-color: #006efe !important;
  color: #fff;
}

/deep/.el-menu-item.is-active {
  background-color: #006efe !important;
  color: #fff;
}

/deep/ .el-icon-arrow-down::before {
  content: "\e790";
  color: #fff;
}

.menu_icon {
  width: 16px;
  height: 16px;
  margin: 0 7px;
}

.menu_icontop {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

/deep/ .el-menu-item-group__title {
  padding: 0;
}

.getcode {
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 40px;
  }
}

.codebtn {
  width: 126px;
  height: 36px;
  background: #33c8b8;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: #ffffff;
  line-height: 36px;
  cursor: pointer;
  text-align: center;
  user-select: none;
  margin-left: 10px;
}
</style>
<style>
.el-menu--vertical .el-menu-item {
  height: 56px;
  line-height: 54px;
}

.el-menu--popup {
  min-width: 170px;
  padding: 0 !important;
}

.el-menu--popup {
  padding: 0;
}

.el-menu--popup-right-start {
  margin: 0;
}
</style>
