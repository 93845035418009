import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'

// 创建axios实例
let service;
if (process.env.NODE_ENV == 'development') {
  service = axios.create({
    baseURL: 'https://api.kicheck.cc',
    // baseURL: 'http://192.168.0.131:9045',
    // baseURL: 'http://116.140.209.171:9002',
    // baseURL: 'https://gateway.joyousoa.com',
    // baseURL: 'http://check.joyousoa.com:8081',
  })
} else {
  service = axios.create({
    baseURL: 'https://api.kicheck.cc',
    // baseURL: 'http://192.168.0.203:9002',
    // baseURL: 'http://127.0.0.1:8081',
    // baseURL: 'https://gateway.joyousoa.com',
    // baseURL:1 'http://116.140.209.171:9002',
    // baseURL: 'http://192.168.0.201:8081',
    // baseURL: 'http://check.joyousoa.com:8081',
  })
}


// request拦截器
service.interceptors.request.use(config => {
  if (window.sessionStorage.getItem('token') && window.sessionStorage.getItem('token').length > 30) {
    config.headers['Authorization'] = "Bearer" + " " + window.sessionStorage.getItem('token')
  } // 让每个请求携带自定义token 请根据实际情况自行修改
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
})
var timeoutflag = null

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
    * code为非20000是抛错 可结合自己业务进行修改
    */
    const res = response
    if (res.status !== 200 && res.status !== 204) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      if (res.status == 401 || res.status == 403) {
        Message({
          message: "权限异常或者无权限,请联系管理员",
          type: 'error',
          duration: 5 * 1000
        })
        return response
      }
      return Promise.reject('error')
    } else {
      return response

    }
  },
  error => {
    let message = error.message
    if (error.response.status === 404) {
      message = '接口访问失败'
    } else if (error.response.status === 403 || error.response.status === 401) {
      store.commit('Setdialogout', true)
      // Message({
      //   message: "登录失效，请重新登录",
      //   type: 'error',
      //   duration: 5 * 1000
      // })
    }

    if (timeoutflag != null) {
      clearTimeout(timeoutflag);
    }
    return Promise.reject(error)
  }
)

export default service
