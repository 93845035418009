<template>
  <div class="main">
    <contenttitle
      title="基本信息"
      :helpUrl="`saas-ban/yong-hu-zhong-xin/ji-ben-xin-xi`"
    ></contenttitle>
    <div class="main_inseate">
      <div class="userinfo flexl">
        <div class="username">
          <div class="usernametitle">用户名：</div>
          <div class="usernumber">
            {{ userInfo.username }}
          </div>
        </div>
        <div class="useritembox flexsb">
          <div class="useritem" style="padding-left: 0">
            <div class="useritem-title">手机号：</div>
            <div class="flexsb" v-if="!mobileActive" style="height: 28px">
              <span class="useritem_info">
                {{ userInfo.mobile }}
              </span>
              <!-- <i
                class="el-icon-edit-outline"
                style="color: #5e94ff; font-size: 18px"
                @click="mobileActive = true"
              ></i> -->
            </div>
            <div v-else class="flexsb changephone">
              <el-input
                size="mini"
                style="width: 156px"
                v-model="userInfo.mobile"
                placeholder="请输入内容"
              ></el-input>
              <div class="flexl">
                <i
                  class="el-icon-close"
                  style="color: #ee4d4d"
                  @click="nochange"
                ></i>
                <i
                  class="el-icon-check"
                  style="color: #8bc94d"
                  @click="changeMobile"
                ></i>
              </div>
            </div>
          </div>
          <div class="useritem">
            <div class="useritem-title">注册时间：</div>
            <div class="useritem_info">
              {{ $moment(userInfo.dateCreated).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </div>
          <div class="useritem">
            <div class="useritem-title">是否关注公众号：</div>
            <div class="flexl" style="position: relative">
              <div class="useritem_info" v-if="userInfo.openId">已关注</div>

              <div v-else class="flexl">
                <div class="useritem_info">未关注</div>
                <div
                  @click="showImg"
                  class="useritem_info"
                  style="margin-left: 15px; color: #409eff; cursor: pointer"
                >
                  关注
                </div>
              </div>

              <div class="img_box" v-if="codeShow">
                <img :src="qrCode" alt="" />
              </div>
            </div>
          </div>
          <div class="useritem" style="border-right: 0; padding-right: 15px">
            <div class="useritem-title">企业名称：</div>
            <div class="flexsb" v-if="!orgActive" style="height: 28px">
              <span class="useritem_info">
                {{ userInfo.orgName }}
              </span>
              <i
                class="el-icon-edit-outline"
                style="color: #5e94ff; font-size: 18px"
                @click="orgActive = true"
              ></i>
            </div>
            <div v-else class="flexsb changephone">
              <el-input
                size="mini"
                style="width: 156px"
                v-model="userInfo.orgName"
                placeholder="请输入内容"
                maxlength="16"
              ></el-input>
              <div class="flexl">
                <i
                  class="el-icon-close"
                  style="color: #ee4d4d"
                  @click="nochange"
                ></i>
                <i
                  class="el-icon-check"
                  style="color: #8bc94d"
                  @click="changeName"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main_content">
        <div class="commonTitle">
          <img src="@/assets/img1/icon/leftto.png" alt="" />
          <div class="buytitle_name">用户身份</div>
        </div>
        <div class="itembox flexl">
          <div class="servename" style="border-left: 1px solid #e9f0f5">
            <div class="servenametitle" style="border-left: none">服务</div>
            <div class="servenameitem">
              专属通道
              <el-tooltip
                class="item"
                effect="light"
                content="由于系统的检测量很大，普通用户需要排队等候；企业用户，使用专属通道，检测更快。"
                placement="bottom"
              >
                <i class="el-icon-info" style="color: #ccc"></i>
              </el-tooltip>
            </div>
            <div class="servenameitem">
              单次检测容量
              <el-tooltip
                class="item"
                effect="light"
                content="普通用户，支持最多100万字的查重检测；企业用户对字数无限制。"
                placement="bottom"
              >
                <i class="el-icon-info" style="color: #ccc"></i>
              </el-tooltip>
            </div>
            <div class="servenameitem">
              多账号
              <el-tooltip
                class="item"
                effect="light"
                content="普通用户，不支持添加子账号；企业用户，支持添加子账号，满足子公司、部门、多人员等的使用需要。"
                placement="bottom"
              >
                <i class="el-icon-info" style="color: #ccc"></i>
              </el-tooltip>
            </div>
            <div class="servenameitem">限制文件个数</div>
            <div class="servenameitem">限制文件大小</div>
            <div class="servenameitem">检测结果导出</div>
            <div class="servenameitem">检测记录存档</div>
            <div class="servenameitem">专属客户经理</div>
            <div class="servenameitem">检测完成通知</div>
            <div class="servenameitem">获取方式</div>
          </div>
          <div class="servename" style="width: 40%">
            <div class="servenametitle">普通用户</div>
            <div
              v-if="userType == 0"
              class="usertype"
              style="
                background: linear-gradient(135deg, #0884ee 0%, #145ed0 100%);
              "
            >
              当前
            </div>
            <div class="servenameitem servetxt">无</div>
            <div class="servenameitem servetxt">100万字</div>
            <div class="servenameitem servetxt">不支持</div>
            <div class="servenameitem servetxt">{{ setting.fileCount }}</div>
            <div class="servenameitem servetxt">
              {{ setting.fileSize }}M/单篇
            </div>
            <div class="servenameitem servetxt">无</div>
            <div class="servenameitem servetxt">无</div>
            <div class="servenameitem servetxt">无</div>
            <div class="servenameitem servetxt">有（需要绑定微信）</div>
            <div class="servenameitem servetxt">注册成功</div>
          </div>
          <div class="servename" style="width: 40%">
            <div class="servenametitle">企业用户</div>
            <div
              v-if="userType == 0"
              class="usertype"
              style="
                background: linear-gradient(144deg, #ffa52e 0%, #e6212a 100%);
              "
            >
              推荐
            </div>
            <div
              v-else
              class="usertype"
              style="
                background: linear-gradient(135deg, #0884ee 0%, #145ed0 100%);
              "
            >
              当前
            </div>
            <!-- 1 -->
            <div class="servenameitem servetxt">有</div>
            <div class="servenameitem servetxt">无限制</div>
            <div class="servenameitem servetxt">支持</div>
            <div class="servenameitem servetxt">
              {{ setting.enterpriseFileCount }}
            </div>
            <div class="servenameitem servetxt">
              {{ setting.enterpriseFileSize }}M/单篇
            </div>
            <div class="servenameitem servetxt">有</div>
            <div class="servenameitem servetxt">有</div>
            <div class="servenameitem servetxt">有</div>
            <div class="servenameitem servetxt">有（需要绑定微信）</div>
            <div class="servenameitem servetxt">
              {{ setting.upgradePrice }}元付费升级
              <div
                class="upgradation"
                v-if="userType == 0"
                @click="upgradation"
              >
                升级
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo } from "@/api/saasuser.js";

import {
  pointsInfo,
  orderupdateCreate,
  updatemobileororgName,
} from "@/api/org.js";

import { getSettings } from "@/api/check.js";
import contenttitle from "@/component/contentTitle";
import {
  getQrToken,
  getLoginQrCode,
  wechatOpenIdLogin,
  updateOpenId,
} from "@/api/saasuser.js";
export default {
  components: { contenttitle },
  data() {
    return {
      codeShow: false,
      orgActive: false,
      mobileActive: false,
      input: "13476829061",
      input1: "彦旭科技",
      userInfo: {},
      userType: 0, //0:普通用户  2 企业用户
      setting: {},
      getToken: "",
      qrCode: "",
      timer: "",
    };
  },
  created() {
    this.getUserinfo();
    this.getuserType();
  },
  methods: {
    async openidUpdate(openid) {
      let { data } = await updateOpenId({
        id: this.userInfo.id,
        openId: openid,
      });
      if (data.code == 200) {
        this.$message({
          message: "感谢您的关注！",
          type: "success",
        });
      } else {
        this.$message({
          message: data.msg || "微信绑定失败",
          type: "error",
        });
      }
      this.getUserinfo();
    },
    async showImg() {
      let { data } = await getQrToken();

      if (data.code == 200) {
        this.getToken = data.data;
        let codeUrl = await getLoginQrCode(this.getToken);
        if (codeUrl.data.code == 200) {
          this.qrCode = codeUrl.data.data;
          this.codeShow = !this.codeShow;

          this.timer = setInterval(async () => {
            let res = await wechatOpenIdLogin({ token: this.getToken });
            if (res.data.code == 200) {
              clearInterval(this.timer);
              this.openId = res.data.data;
              this.openidUpdate(this.openId);
            }
          }, 3000);
        }
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    nochange() {
      this.orgActive = false;
      this.mobileActive = false;
      this.getUserinfo();
    },
    async changeName() {
      let form = {
        id: this.userInfo.id,
        name: this.userInfo.orgName,
      };
      let { data } = await updatemobileororgName(form);
      if (data.code == 200) {
        this.$message({
          type: "success",
          message: "企业名称修改成功!",
        });
        this.orgActive = false;
      } else {
        this.$message({
          message: data.msg || "企业名称修改失败",
          type: "error",
        });
      }
      this.getUserinfo();
    },
    async changeMobile() {
      var reg =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|(147))\\d{8}$";
      var regExp = new RegExp(reg);
      if (regExp.test(this.userInfo.mobile)) {
        let form = {
          id: this.userInfo.id,
          mobile: this.userInfo.mobile,
        };
        let { data } = await updatemobileororgName(form);
        if (data.code == 200) {
          this.$message({
            type: "success",
            message: "手机号修改成功!",
          });
        } else {
          this.$message({
            message: data.msg || "手机号修改失败",
            type: "error",
          });
        }
        this.mobileActive = false;
        this.getUserinfo();
      } else {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
      }
    },
    async upgradation() {
      let { data } = await orderupdateCreate({
        price: this.setting.upgradePrice,
      });
      this.$router.push(
        `/orgindex/chosePay?orderNum=${
          data.data
        }&name=升级为企业用户&price=${this.setting.upgradePrice.toFixed(2)}`
      );
    },
    async getuserType() {
      let { data } = await pointsInfo();
      this.userType = data.userType;
    },
    async getUserinfo() {
      let { data } = await userInfo();
      this.userInfo = data;
      let { data: info } = await getSettings();
      this.setting = info;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .main_inseate {
    background: #f3f4f8;

    .userinfo {
      width: 100%;

      .username {
        width: 227px;
        height: 110px;
        background: #d4e6ff;
        padding: 27px 36px;

        .usernametitle {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 17px;
        }

        .usernumber {
          margin-top: 20px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 17px;
        }
      }

      .useritembox {
        flex: 1;
        height: 110px;
        background: #ffffff;
        margin-left: 20px;
        padding: 20px 16px 20px 27px;

        .line {
          width: 1px;
          height: 70px;
          background: #e4e2e2;
          margin-left: 85px;
        }

        .useritem {
          width: 25%;
          border-right: 1px solid #ccc;
          padding-right: 40px;
          padding-left: 26px;

          .changephone {
            i {
              font-size: 20px;
              margin-left: 7px;
              cursor: pointer;
            }
          }

          .useritem-title {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 17px;
            margin-bottom: 21px;
            margin-top: 6px;
          }

          .useritem_info {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 28px;
          }
        }
      }
    }

    .main_content {
      overflow-y: auto;

      .content-title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2c2d3a;
        line-height: 25px;

        i {
          color: #1990ff;
          font-size: 13px;
        }

        div {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2c2d3a;
          line-height: 25px;
        }
      }

      .itembox {
        margin-top: 15px;

        .servename {
          width: 20%;
          position: relative;

          .usertype {
            width: 60px;
            height: 26px;
            border-radius: 6px 6px 6px 0px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: center;
            position: absolute;
            right: 57px;
            top: -10px;
          }

          .servenametitle {
            height: 56px;
            background: #ffffff;
            border: 1px solid #e9f0f5;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 56px;
            text-align: center;
          }

          .servenameitem {
            height: 40px;
            box-shadow: inset 0px 0px 0px 0px #e0eaf1;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 40px;
            text-align: center;
            border: 1px solid #e0eaf1;
            position: relative;
            border-top: none;
            border-left: none;
          }

          .servetxt {
            color: rgba(2, 2, 2, 0.68) !important;
          }

          .upgradation {
            position: absolute;
            right: 50px;
            top: 0.625rem;
            width: 76px;
            height: 26px;
            background: linear-gradient(180deg, #ffa52e 0%, #ea4e2c 100%);
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: center;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
}
.img_box {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 85px;
  top: 0;
  z-index: 9999;
  img {
    width: 120px;
    height: 120px;
  }
}
</style>