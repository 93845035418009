<template>
  <div>
    <indexTip></indexTip>
    <headerview></headerview>
    <div class="content-wrap">
      <div class="content1">
        <productevaluation
          :productShow="productShow"
          @cancelEvent="cancelEvent"
        ></productevaluation>
        <div class="content1-left">
          <div class="menu-title" v-if="!menucollapse">用户中心</div>
          <el-menu
            :collapse-transition="false"
            :collapse="menucollapse"
            text-color="#fff"
            :default-active="defaultAction"
            class="el-menu-vertical-demo"
            :router="true"
          >
            <el-menu-item index="/orgindex/basicInformation">
              <i class="el-icon-user"></i>
              <span slot="title">基本信息</span>
            </el-menu-item>
            <el-menu-item index="/orgindex/myorder">
              <i class="el-icon-s-order"></i>
              <span slot="title">我的订单</span>
            </el-menu-item>
            <el-menu-item index="/orgindex/invoice">
              <i class="el-icon-s-management"></i>
              <span slot="title">发票管理</span>
            </el-menu-item>
            <el-menu-item v-if="userType == 2" index="/orgindex/accountnumber">
              <i class="el-icon-tickets"></i>
              <span slot="title">账号管理</span>
            </el-menu-item>
          </el-menu>
          <div class="menu_bottom flexc">
            <i
              class="el-icon-s-fold"
              @click="menucollapse = !menucollapse"
              v-if="!menucollapse"
            ></i>
            <i
              class="el-icon-s-unfold"
              @click="menucollapse = !menucollapse"
              v-else
            ></i>
            <div
              class="pointbox"
              v-if="!menucollapse"
              @click="productShow = true"
            >
              <span class="pointinfo">给产品打个分</span>
              <i class="el-icon-d-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="content1-right">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerview from "@/component/header.vue";
import productevaluation from "@/component/productEvaluation";
import { pointsInfo } from "@/api/org.js";
import indexTip from "@/component/indexTip";

export default {
  components: { headerview, productevaluation, indexTip },
  async created() {
    if (this.$route.path == "/index/groupcheckupload") {
      this.defaultAction = "/index/groupcheck";
    } else if (this.$route.path == "/index/reportlist") {
      this.defaultAction = "/index/checkreport";
    } else if (this.$route.path == "/index/groupcheckfile") {
      this.defaultAction = "/index/checkhistory";
    } else {
      this.defaultAction = this.$route.path;
    }
    this.getuserType();
  },

  data() {
    return {
      menucollapse: false,
      defaultAction: "",
      productShow: false,
      userType: {},
    };
  },

  methods: {
    async getuserType() {
      let { data } = await pointsInfo();
      this.userType = data.userType;
    },
    cancelEvent() {
      this.productShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-wrap {
  overflow: hidden;
  background: #f6f9ff;

  .content1 {
    display: flex;
    justify-content: left;
    position: relative;

    .content1-left {
      padding-top: 10px;
      // max-width: 200px;
      height: calc(100vh - 56px);
      background: #ffffff;
      position: relative;
      background: #1f212e;

      /deep/.el-menu {
        background: #1f212e;
        color: #fff !important;
        border: none !important;
      }

      /deep/.el-menu-item:hover {
        background-color: #006efe !important;
        color: #fff;
      }

      /deep/.el-submenu__title:hover {
        background-color: #006efe !important;
        color: #fff;
      }

      /deep/.el-menu-item.is-active {
        background-color: #006efe !important;
        color: #fff;
      }

      .menu-title {
        min-width: 200px;
        height: 50px;
        font-size: 16px;
        font-family: "微软雅黑", "Microsoft Yahei", "Hiragino Sans GB", Tahoma,
          Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: #ffffff;
        line-height: 50px;
        padding-left: 20px;
      }

      .menu_bottom {
        width: 100%;
        height: 48px;
        background: #26303e;
        position: absolute;
        bottom: 0;
        left: 0;

        .el-icon-s-fold {
          cursor: pointer;
          font-size: 18px;
          color: #fff;
        }

        .el-icon-s-unfold {
          cursor: pointer;
          font-size: 18px;
          color: #fff;
        }

        .pointbox {
          margin-left: 28px;
          cursor: pointer;

          .pointinfo {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }

          .el-icon-d-arrow-right {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            margin-left: 10px;
          }
        }
      }

      /deep/.el-menu {
        background: #1f212e;
        color: #fff !important;
      }

      .companybox {
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 1200;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }

  .content1-right {
    flex: 1;
    height: calc(100vh - 56px);
    overflow: auto;
  }
}
</style>
