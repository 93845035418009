<template>
  <div class="tip">
    <div @click="showTip" id="tipList" class="tipList">
      <i class="el-icon-service"></i>
      <span v-if="showtipbox">在线咨询</span>
    </div>
    <div class="list">
      <div class="payroll_wx_Father">
        <img src="@/assets/img1/wx.png" class="tipsImage" />
        <span class="tipsText"> 客户经理微信 </span>
        <div class="imgWrap">
          <img src="@/assets/vx/zsqvx.jpg" />
          <img src="@/assets/vx/zmvx.jpg" />
        </div>
      </div>
      <div class="payroll_wx_Father">
        <img src="@/assets/img1/workvx.png" class="tipsImage" />
        <span class="tipsText"> 客户经理企业微信 </span>
        <div class="imgWrap">
          <img src="@/assets/vx/zsqWork.jpg" />
          <img src="@/assets/vx/zmWork.jpg" />
        </div>
      </div>
      <div class="payroll_wx_Father">
        <img src="@/assets/img1/icon-gzh.png" class="tipsImage" />
        <span class="tipsText"> 关注微信公众号 </span>
        <div class="imgWrap" style="width: auto; left: -130px">
          <img src="@/assets/img1/qrcode.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showtipbox: false,
    };
  },
  methods: {
    showTip() {
      this.showtipbox = !this.showtipbox;
    },
  },
};
</script>

<style lang="less" scoped>
.tip {
  position: fixed;
  bottom: 5vh;
  right: 0px;
  font-size: 40px;
  z-index: 1000;
  user-select: none;

  .tipBtn {
    width: 80px;
    cursor: pointer;
  }

  .list {
    display: none;
    position: absolute;
    top: -115px;
    right: 10px;
    width: 165px;
    font-size: 14px;
    color: #555;
    background: #fff;
    box-shadow: 0 0 6px rgba(46, 122, 234, 0.8);
    border-radius: 8px;
    padding: 20px 0;
    .payroll_wx_Father:hover {
      .imgWrap {
        display: flex;
      }
    }
    .payroll_wx_Father {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 38px;
      position: relative;

      .tipsImage {
        width: 20px;
        margin: 0 15px 0 10px;
      }
      .tipsText {
        color: #666;
        font-size: 14px;
      }
      .imgWrap {
        width: 260px;
        display: none;
        position: absolute;
        left: -270px;
        top: 0;
        align-items: center;
        justify-content: space-around;
        bottom: -30px;
        img {
          width: 120px;
        }
      }
    }
  }
}

.tip:hover .list {
  display: block;
}

.tipList {
  padding: 0 20px;
  max-width: 160px;
  height: 46px;
  background: rgb(46, 122, 234);
  box-shadow: 0px 8px 16px 0px rgba(46, 122, 234, 0.15);
  border-radius: 28px 0px 0px 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 46px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;

  span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
  }
}
</style>