<template>
  <div style="height: 100%">
    <div class="main" v-if="!applyActive">
      <div class="searchBox flexl">
        <el-input v-model="tableRequest.code" style="width: 220px" placeholder="请输入订单编号"></el-input>
        <!-- <el-date-picker v-model="value1" type="date" placeholder="请选择创建时间" style="margin:0 10px">
        </el-date-picker> -->
        <div style="margin: 0 10px">
          <!-- <el-date-picker
            v-model="searchTime"
            type="daterange"
            start-placeholder="开始日期"
            @change="changetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker> -->
          <el-date-picker v-model="searchTime" type="daterange" start-placeholder="开始日期" @change="changetime"
            value-format="yyyy-MM-dd" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="mainBtn" @click="getlist">
          <i class="el-icon-search"></i> 搜索
        </div>
        <div style="margin-left: 10px" class="mainBtnWhite" @click="reset">
          <i class="el-icon-refresh-left"></i> 重置
        </div>
      </div>
      <div class="tableBox">
        <el-table :header-cell-style="headerCellStyle" :row-style="rowStyle" :data="tableData"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column prop="code" label="订单编号" width="250" align="center"></el-table-column>
          <el-table-column prop="createdBy" label="创建人" align="center" width="150"></el-table-column>
          <el-table-column prop="dateCreated" label="创建时间" align="center" width="150">
            <template slot-scope="scope">
              {{ $moment(scope.row.dateCreated).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="套餐类型" align="center">
            <template slot-scope="scope">
              {{ scope.row.pointsId ? "知点" : "" }}
              {{ scope.row.packageId ? "知卡" : "" }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="套餐内容" align="center">
            <template slot-scope="scope">
              {{
                scope.row.pointsName
                  ? scope.row.pointsName + "x" + scope.row.pointsNum
                  : ""
              }}
              {{
                scope.row.packageName
                  ? scope.row.packageName + "x" + scope.row.packageNum
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="实付金额" prop="amountOfMoney" align="center">
            <template slot-scope="scope">
              ￥{{ scope.row.amountOfMoney }}
            </template>
          </el-table-column>
          <el-table-column label="申请发票" align="center">
            <template slot-scope="scope">
              <div @click="handleDefault(scope.row)" class="applybtn">
                申请发票
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="applyBottom flexl">
        <span class="selectInfo">
          共{{ total }}个订单可申请发票，已选择{{
            selectData.length
          }}个，合计金额：</span>
        <span class="selectMoney">¥ {{ totalmoney }}元</span>
        <div class="mainBtn" @click="checkmore">批量申请发票</div>
      </div>
    </div>
    <div v-else class="applymain">
      <div style="height: calc(100% - 80px); overflow: auto">
        <div class="backto flexl">
          <div @click="backto">
            <i class="el-icon-arrow-left"></i>
            <span>返回</span>
          </div>
        </div>
        <div class="commonTitle" style="margin: 13px 0">
          <img src="@/assets/img1/icon/leftto.png" alt="" />
          <div class="buytitle_name" style="font-weight: 600">开票信息</div>
          <div style="font-size: 12px; color: #d01814; margin-left: 4px">
            （请提前到“发票信息管理”页面设置开票信息）
          </div>
        </div>
        <div class="formbox">
          <el-form label-position="left" :show-message="false" :model="ruleForm" :rules="rules" ref="ruleForm"
            label-width="136px" class="demo-ruleForm">
            <el-form-item label="开具类型" prop="createType" required>
              {{ ruleForm.createType == 0 ? "企业" : "个人" }}
              <!-- <el-radio-group v-model="ruleForm.createType" @change="changeCreateType">
                <el-radio :label="0">企业</el-radio>
                <el-radio :label="1">个人</el-radio>
              </el-radio-group> -->
            </el-form-item>
            <el-form-item label="发票抬头" prop="invoiceHeader" required>
              {{ ruleForm.invoiceHeader }}
              <!-- <el-input v-model="ruleForm.invoiceHeader" style="width:485px"></el-input> -->
            </el-form-item>
            <el-form-item label="发票类型" prop="invoiceType" required>
              {{
                ruleForm.invoiceType == 0
                  ? "电子普通发票"
                  : "电子增值税专用发票"
              }}
              <!-- <el-radio-group v-model="ruleForm.invoiceType">
                <el-radio :label="0">电子普通发票</el-radio>
                <el-radio :label="1" v-if="ruleForm.createType == 0"
                  >电子增值税专用发票</el-radio
                >
              </el-radio-group> -->
              <!-- <span class="typeinfo" v-if="ruleForm.createType == 0">（金额在1000元以内由客户承担快递费，默认发顺丰）</span> -->
            </el-form-item>
            <el-form-item label="税务登记证号" prop="taxpayerId" required v-if="ruleForm.createType == 0">
              {{ ruleForm.taxpayerId }}
              <!-- <el-input v-model="ruleForm.taxpayerId" style="width:485px"></el-input> -->
            </el-form-item>
            <el-form-item label="基本开户银行名称" prop="bank" required v-if="ruleForm.invoiceType == 1">
              {{ ruleForm.bank }}
              <!-- <el-input v-model="ruleForm.bank" style="width:485px"></el-input> -->
            </el-form-item>
            <el-form-item label="基本开户账号" prop="bankAccount" required v-if="ruleForm.invoiceType == 1">
              {{ ruleForm.bankAccount }}
              <!-- <el-input v-model="ruleForm.bankAccount" style="width:485px"></el-input> -->
            </el-form-item>
            <el-form-item label="注册场所地址" prop="address" required v-if="ruleForm.invoiceType == 1">
              {{ ruleForm.address }}
              <!-- <el-input v-model="ruleForm.address" style="width:485px"></el-input> -->
            </el-form-item>
            <el-form-item label="注册固定电话" prop="phone" required v-if="ruleForm.invoiceType == 1">
              {{ ruleForm.phone }}
              <!-- <el-input v-model="ruleForm.phone" style="width:485px"></el-input> -->
            </el-form-item>
            <el-form-item label="邮箱地址" prop="email" required>
              {{ ruleForm.email }}
              <!-- <el-input v-model="ruleForm.email" style="width:485px"></el-input> -->
            </el-form-item>
            <el-form-item label="一般纳税人证明" prop="img" required v-if="ruleForm.invoiceType == 1">
              <img v-if="ruleForm.img" :src="$http.defaults.baseURL +
                '/bid-check-service/open/image?path=' +
                ruleForm.img
                " alt="" class="showimg" />
            </el-form-item>
          </el-form>
        </div>
        <div class="commonTitle" style="margin: 13px 0">
          <img src="@/assets/img1/icon/leftto.png" alt="" />
          <div class="buytitle_name" style="font-weight: 600">备注信息</div>
        </div>
        <div class="memo">
          <el-input resize="none" style="width: 633px" type="textarea" :rows="3" placeholder=""
            v-model="ruleForm.remark">
          </el-input>
        </div>
        <div class="memeinfo">
          注：建议不要超过40-50（数字+汉字），否则盖章的时候有可能会被遮挡到！
        </div>
        <div class="commonTitle" style="margin: 13px 0" v-if="false">
          <img src="@/assets/img1/icon/leftto.png" alt="" />
          <div class="buytitle_name" style="font-weight: 600">地址信息</div>
          <div class="titleinfo">
            （请提前到“地址管理”页面设置寄送地址信息）
          </div>
        </div>
        <div class="addresstable" v-if="false">
          <el-table :data="addressData" style="width: 100%" :header-cell-style="headerCellStyle">
            <el-table-column align="center" width="50">
              <template slot-scope="scope">
                <div class="el-radio-hidden-label">
                  <el-radio :label="scope.row.id" v-model="addressRadio"></el-radio>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="receiveName" label="收件人姓名" align="center">
            </el-table-column>
            <el-table-column prop="receivePhone" label="电话号码" align="center">
            </el-table-column>
            <el-table-column prop="receiveAddress" label="地址" align="center">
            </el-table-column>
            <el-table-column prop="receivePostcode" label="邮编" align="center">
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <div class="addressbtns" v-if="scope.row.defaultAddress">
                  默认
                </div>
                <div class="addressbtns" v-else @click="checkdefault(scope.row)">
                  设为默认
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="applyBottom flexl">
        <span class="selectInfo">
          您选取了{{
            ruleForm.orderIds.split(",").length
          }}条单据开具发票，开票金额：</span>
        <span class="selectMoney">¥ {{ ruleForm.totalAmount }}元</span>
        <div class="mainBtn" @click="applySend">确认开票</div>
        <div class="mainBtnWhite" @click="backto" style="padding: 10px 32px; margin-left: 10px">
          取消
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import {
  orderbuyList,
  invoiceaddressList,
  clientinvoiceInfo,
  invoiceorderAdd,
  invoiceaddressDefault,
} from "@/api/org.js";

export default {
  data() {
    return {
      searchTime: "",
      addressRadio: "",
      tableData: [],
      tableRequest: {
        pageNumber: 0,
        size: 9999,
        status: 2,
        invoice: false,
        code: "",
      },
      total: 0,
      headerCellStyle: {
        height: "56px",
        background: " #D4E6FF",
        fontSize: "12px",
        color: " #000000",
      },
      rowStyle: {
        height: "56px",
      },
      isBtnLoading: false,
      applyActive: false,
      rules: {
        createType: [
          { required: true, message: "请选择开具类型", trigger: "blur" },
        ],
        invoiceHeader: [
          { required: true, message: "请输入发票抬头", trigger: "blur" },
          {
            pattern: /^\S{2,20}$/,
            message: "请输入正确的发票抬头(2-20个字符)",
            trigger: "blur",
          },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        taxpayerId: [
          { required: true, message: "请输入税务登记证号", trigger: "blur" },
          {
            pattern: /^\S{4,20}$/,
            message: "请输入正确的税务登记证号(5-20个字符)",
            trigger: "blur",
          },
        ],
        bank: [
          {
            required: true,
            message: "请输入基本开户银行名称",
            trigger: "blur",
          },
          {
            pattern: /^\S{4,20}$/,
            message: "请输入正确的基本开户银行名称(4-20个字符)",
            trigger: "blur",
          },
        ],
        bankAccount: [
          { required: true, message: "请输入基本开户账号", trigger: "blur" },
          {
            pattern: /^[0-9]{12,30}$/,
            message: "请输入正确的基本开户账号",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入注册场所地址", trigger: "blur" },
          {
            pattern: /^\S{5,70}$/,
            message: "请输入正确的注册场所地址(5-70个字符)",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入注册固定电话", trigger: "blur" },
          {
            pattern: /^(0\d{2,3}-?)?\d{7,8}(-\d{1,4})?$/,
            message: "请输入正确的注册固定电话",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            pattern:
              /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        img: [
          { required: true, message: "请上传一般纳税人证明", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      selectData: [],
      addressRequest: {
        pageNumber: 0,
        size: 999,
      },
      addressData: [],
      ruleForm: {},
      totalmoney: 0,
    };
  },
  created() {
    this.getlist();
    this.getInfo();
  },
  methods: {
    changetime(val) {
      if (val) {
        this.tableRequest.startDate = val[0];
        this.tableRequest.endDate = val[1];
      } else {
        this.tableRequest.startDate = "";
        this.tableRequest.endDate = "";
      }
    },
    reset() {
      (this.tableRequest = {
        pageNumber: 0,
        size: 9999,
        status: 2,
        invoice: false,
        code: "",
        startDate: "",
        endDate: "",
      }),
        (this.searchTime = "");
      this.getlist();
    },
    handleCurrentChange(val) {
      this.tableRequest.pageNumber = val - 1;
      this.getlist();
    },
    async checkdefault(row) {
      const { data } = await invoiceaddressDefault(row.id);
      if (data.code == 200) {
        this.$notify({
          title: "成功",
          message: "设为默认地址成功",
          type: "success",
        });
      }
      this.getaddress();
    },
    checkmore() {
      if (this.selectData.length != 0) {
        let ids = [];
        let total = 0;
        this.selectData.forEach((item) => {
          total += item.amountOfMoney;
          ids.push(item.code);
        });
        this.ruleForm.orderIds = ids.toString();
        this.ruleForm.totalAmount = total.toFixed(2);
        this.applyActive = true;
      } else {
        this.$notify({
          title: "失败",
          message: "请先勾选需要开具发票的订单",
          type: "warning",
        });
      }
    },
    changeCreateType(val) {
      if (val == 1) {
        this.ruleForm.invoiceType = 0;
      }
    },
    async applySend() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          // if (this.ruleForm.invoiceType == 1 && !this.addressRadio) {
          //   this.$notify({
          //     title: '失败',
          //     message: '请先勾选寄送地址信息',
          //     type: 'warning',
          //   })
          //   return
          // }
          const form1 = { ...this.ruleForm };

          delete form1.createdBy;
          delete form1.dateCreated;
          delete form1.deleted;
          delete form1.lastUpdated;
          delete form1.lastUpdatedBy;
          delete form1.version;
          delete form1.id;

          form1.openType = form1.createType;
          form1.addressId = this.addressRadio;
          let { data } = await invoiceorderAdd(form1);
          if (data.code == 200) {
            this.$notify({
              title: "成功",
              message: "开票申请提交成功",
              type: "success",
            });
          }
          this.applyActive = false;
          this.totalmoney = 0;
          this.selectData = [];
        } else {
          this.$notify({
            title: "失败",
            message: "请到发票信息管理中将信息填写完整",
            type: "warning",
          });
        }
        this.getlist();
      });
    },
    async getInfo() {
      let { data } = await clientinvoiceInfo();
      this.ruleForm = data;
      this.getaddress();
    },
    async getaddress() {
      let { data } = await invoiceaddressList(this.addressRequest);
      this.addressData = data.content;
      data.content.forEach((item) => {
        if (item.defaultAddress) {
          this.addressRadio = item.id;
        }
      });
    },
    async getlist() {
      let { data } = await orderbuyList(this.tableRequest);
      this.tableData = data.content;
      console.log(data, "数据---");
      // this.tableData.push({}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {})
      // this.total = data.totalElements
      this.$set(this, "total", data.totalElements);
    },
    backto() {
      this.applyActive = false;
      this.totalmoney = 0;
    },
    handleSelectionChange(val) {
      this.selectData = val;
      let total = 0;
      this.selectData.forEach((item) => {
        total += item.amountOfMoney;
      });
      this.totalmoney = total.toFixed(2);
    },
    handleDefault(row) {
      this.applyActive = true;
      this.ruleForm.orderIds = row.code;
      this.ruleForm.totalAmount = row.amountOfMoney;
    },
  },
};
</script>

<style lang="less" scoped>
.applymain {
  height: 100%;
  background: #ffffff;
  overflow: auto;
  padding: 11px 22px 0 22px;
  position: relative;

  .backto {
    cursor: pointer;
    user-select: none;

    i {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-right: 6px;
    }

    div {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      user-select: none;
    }
  }

  .formbox {
    margin-bottom: 20px;

    .typeinfo {
      font-size: 12px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d01410;
      line-height: 17px;
    }

    /deep/.el-radio__label {
      font-size: 12px !important;
    }

    /deep/.el-form-item {
      margin-bottom: 8px;
    }

    /deep/.el-form-item__label {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }

    /deep/.el-form-item__content {
      font-size: 12px;
    }
  }

  .memeinfo {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d01410;
    line-height: 17px;
    padding-left: 5px;
    margin-top: 12px;
  }

  .addresstable {
    margin: 16px 0 34px 0;

    /deep/.el-radio__label {
      display: none !important;
    }
  }

  .titleinfo {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d01410;
  }

  .addressbtns {
    font-size: 12px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2892f7;
    line-height: 20px;
    cursor: pointer;
    user-select: none;
  }

  .applyBottom {
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px -1px 4px 0px rgba(176, 176, 176, 0.5);
    padding-left: 22px;

    .selectInfo {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7);
      line-height: 22px;
    }

    .selectMoney {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d01410;
      line-height: 22px;
      min-width: 100px;
    }
  }

  .applycontent {
    padding: 20px;
    background: #fff;
    height: calc(100vh - 156px);
    overflow: auto;

    .typeinfo {
      font-size: 12px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff0000;
      line-height: 17px;
    }

    .formbox {
      margin-bottom: 20px;

      .typeinfo {
        font-size: 12px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff0000;
        line-height: 17px;
      }

      /deep/.el-form-item {
        margin-bottom: 8px;
      }

      /deep/.el-form-item__label {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .memobox {
      .memo {
        width: 633px;
        margin: 12px 0;
        padding-left: 5px;
      }
    }

    .addressbox {
      margin-top: 20px;

      .addressbtn {
        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 16px;
          padding: 12px 22px;
          cursor: pointer;
          user-select: none;
          border-radius: 4px;
        }

        .applyok {
          background: linear-gradient(360deg, #157bd1 0%, #2da1f8 100%);
          color: #ffffff;
        }

        .applyno {
          background: linear-gradient(360deg, #eaeef3 0%, #ffffff 100%);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
          border: 1px solid #ced0da;
          padding: 11px 36px;
          margin-left: 10px;
        }
      }
    }

    .typeinfo {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff0000;
      line-height: 17px;
    }

    .applymoney {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
      line-height: 22px;
      margin-bottom: 8px;
    }

    .applytitlebox {
      img {
        width: 9px;
      }

      .applytitle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 550;
        color: #000000;
        line-height: 20px;
        margin: 0 5px;
      }

      .titleinfo {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff0000;
      }
    }
  }
}

.main {
  background: #fff;
  padding: 20px 19px;
  height: 100%;
  position: relative;

  .searchBox {
    margin-bottom: 15px;
  }

  .tableBox {
    width: calc(100vw - 471px);
    overflow: hidden;

    /deep/.el-table__body-wrapper {
      max-height: calc(100vh - 357px);
      overflow-y: auto;
    }
  }

  .applyBottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px -1px 4px 0px rgba(176, 176, 176, 0.5);
    padding-left: 22px;

    .selectInfo {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7);
      line-height: 22px;
    }

    .selectMoney {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d01410;
      line-height: 22px;
      min-width: 100px;
    }
  }

  .top {
    margin-bottom: 18px;

    .title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2c2d3a;
      line-height: 20px;
    }

    .checkmore {
      padding: 8px 18px;
      background: #ecf5ff;
      border-radius: 4px;
      border: 1px solid #1990ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1990ff;
      line-height: 20px;
      cursor: pointer;
      user-select: none;
    }
  }
}

.applybtn {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #006efe;
  line-height: 17px;
  cursor: pointer;
  user-select: none;
}

.showimg {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.addressbox {
  /deep/.el-radio__label {
    display: none !important;
  }
}

.pagebox {
  margin-top: 15px;
  text-align: right;
}

/deep/.cell {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 16px;
}
</style>